export const theme = {
    colors: {
      primary: '#00bcd4',    // Azul claro
      secondary: '#ff4081',  // Rosa
      tertiary: '#0C0C0C',// cinza escuro
       
    },
     
  };
  
  export default theme;